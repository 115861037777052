// eslint-disable-next-line jira/restricted/react-lazy
import React, { lazy, useLayoutEffect, useCallback, useMemo } from 'react';
import type {
	GadgetMetricsEventType,
	GadgetContentType,
} from '@atlassian/jira-dashboard-common/src/types.tsx';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import {
	DASHBOARD_GADGET_MODULE,
	ENTRY_POINT_EDIT,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { DashboardGadgetExtensionData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type { InitialRenderPayload } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension/index.tsx';
import type {
	LazyComponentProps,
	LazyRenderer,
} from '@atlassian/jira-forge-ui-async/src/types.tsx';
import type { DashboardGadgetView } from '../view/index.tsx';
import type { DashboardGadgetEdit } from '../edit/index.tsx';

const GADGET_START_EVENT = 'gadget-start';
const GADGET_FINISH_EVENT = 'gadget-rendering-finished';

export type ViewProps = LazyComponentProps<typeof DashboardGadgetView> & {
	onReportGadgetMetrics: (
		eventType: GadgetMetricsEventType,
		contentType: GadgetContentType,
	) => void;
};

export type EditProps = LazyComponentProps<typeof DashboardGadgetEdit> & {
	onReportGadgetMetrics: (
		eventType: GadgetMetricsEventType,
		contentType: GadgetContentType,
	) => void;
};

// We are using React.lazy() instead of react-loosley-lazy here because DashboardGadget
// renders RendererNext from the forge-ui package which uses Suspense
// and you can't mix React.Suspense with RLL

const getUiKitModule = () =>
	import(/* webpackChunkName: "async-forge-ui-dashboard-gadget" */ './index');

export const LazyDashboardGadgetView: LazyRenderer<typeof DashboardGadgetView> = withMetrics(
	lazy(() => getUiKitModule().then((module) => ({ default: module.DashboardGadgetView }))),
);

export const LazyDashboardGadgetEdit: LazyRenderer<typeof DashboardGadgetEdit> = withMetrics(
	lazy(() => getUiKitModule().then((module) => ({ default: module.DashboardGadgetEdit }))),
);

const getForgeExtensionModule = () =>
	import(
		/* webpackChunkName: "async-forge-ui-dashboard-gadget-extension" */ '@atlassian/jira-forge-dashboard-gadget/src/ui/extension/index.tsx'
	);

export const LazyDashboardGadgetViewExtension = withMetrics(
	lazy(() =>
		getForgeExtensionModule().then((module) => ({ default: module.DashboardGadgetViewExtension })),
	),
);

export const LazyDashboardGadgetEditExtension = withMetrics(
	lazy(() =>
		getForgeExtensionModule().then((module) => ({ default: module.DashboardGadgetEditExtension })),
	),
);

const useDashboardGadget = <ED extends Omit<DashboardGadgetExtensionData, 'type'>>(
	onReportGadgetMetrics: (
		eventType: GadgetMetricsEventType,
		contentType: GadgetContentType,
	) => void,
	extensionData: ED,
	entryPoint?: typeof ENTRY_POINT_EDIT,
) => {
	const onInitialRender = useCallback(
		(payload?: InitialRenderPayload) => {
			if (payload !== undefined && payload.error == null) {
				onReportGadgetMetrics(GADGET_FINISH_EVENT, 'View');
			} else {
				onReportGadgetMetrics(GADGET_FINISH_EVENT, 'ErrorMessage');
			}
		},
		[onReportGadgetMetrics],
	);

	useLayoutEffect(() => {
		onReportGadgetMetrics(GADGET_START_EVENT, 'View');
	}, [onReportGadgetMetrics]);

	return useMemo(() => {
		const extraPropsUiCommon = {
			module: DASHBOARD_GADGET_MODULE,
			entryPoint,
			extensionData: {
				...extensionData,
				entryPoint,
				type: DASHBOARD_GADGET_MODULE,
			},
		};
		return { onInitialRender, extraPropsUiCommon };
	}, [entryPoint, extensionData, onInitialRender]);
};

export const AsyncDashboardGadgetView = (props: ViewProps) => {
	const { extensionData, onReportGadgetMetrics, ...restProps } = props;
	const { onInitialRender, extraPropsUiCommon } = useDashboardGadget(
		onReportGadgetMetrics,
		extensionData,
	);
	const extraPropsUiKit = { ...extraPropsUiCommon, onInitialRender };
	const extraPropsCustomUi = { ...extraPropsUiCommon, onLoad: onInitialRender };

	if (isNativeUiExtension(props.extension) || isCustomUIExtension(props.extension)) {
		return <LazyDashboardGadgetViewExtension {...restProps} {...extraPropsCustomUi} />;
	}

	return <LazyDashboardGadgetView {...restProps} {...extraPropsUiKit} />;
};

export const AsyncDashboardGadgetEdit = (props: EditProps) => {
	const { extensionData, onReportGadgetMetrics, ...restProps } = props;
	const { onInitialRender, extraPropsUiCommon } = useDashboardGadget(
		onReportGadgetMetrics,
		extensionData,
		ENTRY_POINT_EDIT,
	);
	const extraPropsUiKitEdit = { ...extraPropsUiCommon, onInitialRender };
	const extraPropsCustomUiEdit = { ...extraPropsUiCommon, onLoad: onInitialRender };

	if (
		isNativeUiExtension(props.extension, ENTRY_POINT_EDIT) ||
		isCustomUIExtension(props.extension, ENTRY_POINT_EDIT)
	) {
		return <LazyDashboardGadgetEditExtension {...restProps} {...extraPropsCustomUiEdit} />;
	}

	return <LazyDashboardGadgetEdit {...restProps} {...extraPropsUiKitEdit} />;
};
